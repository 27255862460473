.navbar__footer {
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar__footer--container {
    width: 100%;
    max-width: 450px;
    box-shadow: 0 2px 4px #00000030, 0 -2px 4px #00000030;
}
