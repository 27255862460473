.App {
    min-height: 100vh;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(255, 255, 255);
}

.container-fluid {
    width: 100%;
    display: flex;
    height: 100dvh;
    max-width: 450px;
    flex-direction: column;
    box-shadow: 0 2px 4px #00000030, 0 -2px 4px #00000030;
    background: url(../../../../public/expertrans/bg_content.jpg);
    background-size: cover;
    // background-image: linear-gradient(to right, white 0%, #f8c0cd 100%);
}

.container {
    width: 100%;
    display: flex;
}

.content {
    flex: 1;
    width: 100%;
    padding: 0px 10px 57.5px 10px;
    height: 100dvh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.content::-webkit-scrollbar {
    display: none;
}
